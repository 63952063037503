<script setup lang="ts">
const props = withDefaults(
    defineProps<{
        src?: string | undefined
    }>(),
    {
        src: '/images/white-logo.svg'
    }
)
</script>
<template>
    <img
        :src="props.src"
        alt="Filtr Logo"
        class="w-32" />
</template>
